import React, { Component } from 'react'
import { Form, Popup, Input, TextArea } from "semantic-ui-react";
import axios from "axios";
import { baseURL } from "../../../../configuration/constants";
import { getHeaders, loadImage } from "../../../helper";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { Link } from 'react-router-dom';

class PillWeightOfPrimePowder extends Component {

    state = { performed_by_pin: "", confirmed_by_pin: "", performed_by: "", performed_by_name: "", confirmed_by: "", confirmed_by_name: "", target_pill_weight: "", primary_operator: "", secondary_operator: "", measured_mg: "", secondary_signoff_name: "", primary_operator_name: "", step_prev: "pilling_of_mixed_prime_ingredient", step_next: "pilling_of_mixed_flourish_ingredient", formErrors: { performed_by_pin: false, confirmed_by_pin: false, primary_operator: false, secondary_signoff: false, measured: false } }

    componentDidMount() {
        this.getCurrentStep('pill_weight_of_prime_powder');
        if (this.props.selected_row_value.flourish_capsule_size === 'powder') {
            this.setState({ target_pill_weight: "5", step_prev: "weight_of_mixed_prime_ingredients", step_next: "pill_packaging_flourish" })
        } else {
            this.setState({ target_pill_weight: "460" })
        }
    }
    getCurrentStep = (step_name) => {
        let req = {
            formula_id: this.props.selected_row_value.formula_id,
            step_name: step_name
        }
        axios.post(baseURL + "/get-current-step", req, getHeaders())
            .then(res => {
                let result = res.data;
                if (result.success === true && result.current_step !== null) {
                    let current_step = result.current_step;
                    let step_json = JSON.parse(current_step.step_json);
                    console.log(step_json);
                    this.setState({ step_current: current_step });
                    // this.onInputChange(null,{name:"performed_by_pin","value":current_step.performed_by});
                    // this.onInputChange(null,{name:"confirmed_by_pin","value":current_step.confirmed_by});
                    this.onInputChange(null, { name: "measured", "value": (step_json.measured * 10) / 1000 });
                    this.onInputChange(null, { name: "primary_operator", "value": step_json.primary_operator });
                    this.onInputChange(null, { name: "secondary_signoff", "value": step_json.secondary_signoff });
                    this.onInputChange(null, { name: "operator_notes", "value": step_json.operator_notes });
                }
            })
    }

    handleClick = () => {
        // Call the function passed from the parent
        this.props.onPrevClick(this.state.step_prev);
    };
    handleNextClick = () => {
        // Call the function passed from the parent
        // this.props.onNextClick('pilling_of_mixed_flourish_ingredient');
        this.props.onNextClick(this.state.step_next)
    };
    handlePauseClick = () => {
        this.props.onNextClick('pause');
    }
    handleQuitClick = () => {
        this.props.onNextClick('quit');
    }
    onInputChange = (e, { name, value }) => {
        console.log(name);
        switch (name) {
            case "performed_by_pin":
                if (value.length >= 3) {
                    if (this.state.confirmed_by_pin !== value) {
                        this.getPerformUser(value, "performer");
                    } else {
                        toast.error("Please enter the another pin");
                    }
                } else {
                    this.setState({ performed_by: "", performed_by_name: "" });
                }
                break;
            case "confirmed_by_pin":
                if (value.length >= 3) {
                    if (this.state.performed_by_pin !== value) {
                        this.getPerformUser(value, "confirmer");
                    } else {
                        toast.error("Please enter the another pin");
                    }
                } else {
                    this.setState({ confirmed_by: "", confirmed_by_name: "" });
                }
                break;
            case "primary_operator":
                this.setState({ primary_operator: value });
                if (value.length >= 3) {
                    if (this.state.secondary_signoff !== value) {
                        this.getPerformUser(value, "primary_operator");
                    } else {
                        toast.error("Please enter another pin");
                    }
                } else {
                    this.setState({ primary_operator_name: "" });
                }
                break;
            case "secondary_signoff":
                this.setState({ secondary_signoff: value });
                if (value.length >= 3) {
                    if (this.state.primary_operator !== value) {
                        this.getPerformUser(value, "secondary_signoff");
                    } else {
                        toast.error("Please enter another pin");
                    }
                } else {
                    this.setState({ secondary_signoff_name: "" });
                }
                break;
            case 'measured':
                this.setState({ measured_mg: ((value / 10) * 1000).toFixed(2) });
                break;
            default:
                break;
        }

        this.setState({ [name]: value });

    };
    getPerformUser = (pin, user_type) => {
        if (!pin) {
            toast.error("Please fill pin number.");
            return;
        }
        axios.post(baseURL + "/users/getUserByPin", { pin: pin }, getHeaders())
            .then(res => {
                if (res.data.success === true) {
                    let user = res.data.user;
                    switch (user_type) {
                        case "performer":
                            this.setState(prevState => ({
                                performed_by: user ? user.id : "",
                                performed_by_name: user ? user.full_name : "",
                                formErrors: {
                                    ...prevState.formErrors,
                                    performed_by_pin: user ? false : true
                                }
                            }));
                            break;
                        case "confirmer":
                            this.setState(prevState => ({
                                confirmed_by: user ? user.id : "",
                                confirmed_by_name: user ? user.full_name : "",
                                formErrors: {
                                    ...prevState.formErrors,
                                    confirmed_by_pin: user ? false : true
                                }
                            }));
                            break;
                        case "primary_operator":
                            this.setState(prevState => ({
                                primary_operator_name: user ? user.full_name : "",
                                formErrors: {
                                    ...prevState.formErrors,
                                    primary_operator: user ? false : true
                                }
                            }));
                            break;
                        case "secondary_signoff":
                            this.setState(prevState => ({
                                secondary_signoff_name: user ? user.full_name : "",
                                formErrors: {
                                    ...prevState.formErrors,
                                    secondary_signoff: user ? false : true
                                }
                            }));
                            break;
                        default:
                            break;
                    }

                    //this.setState({user_full_name:user.full_name}); 
                }
            })
    }

    handleSubmit = () => {
        let input_field = false
        if (this.state.measured == "" || this.state.primary_operator == "" || this.state.secondary_signoff == "" || this.state.primary_operator_name == "" || this.state.secondary_signoff_name == "") {
            input_field = true;
        }


        this.setState((prevState) => ({
            formErrors: {
                ...prevState.formErrors, // spread the existing formErrors
                // performed_by_pin: !this.state.performed_by_name?true:false,
                // confirmed_by_pin: !this.state.confirmed_by_name?true:false,
                primary_operator: !this.state.primary_operator_name ? true : false,
                secondary_signoff: !this.state.secondary_signoff_name ? true : false,
                measured: !this.state.measured ? true : false
            }
        }));

        if (input_field) {
            toast.error("Please complete all required field(s) ");
            return;
        }

        // if (!this.state.confirmed_by_pin || !this.state.performed_by_pin) {
        //     toast.error("PIN is missing");
        //     return;
        // }

        // if ((!this.state.performed_by_name && this.state.performed_by_pin) || 
        //     (!this.state.confirmed_by_name && this.state.confirmed_by_pin) ) {
        //     toast.error("PIN is incorrect");
        //     return;
        // }

        let step_json = {
            target_pill_weight: this.state.target_pill_weight,
            operator_notes: this.state.operator_notes,
            measured: this.state.measured_mg,
            /* yield:this.state.yield, */
            primary_operator: this.state.primary_operator,
            secondary_signoff: this.state.secondary_signoff
        }
        let param = {
            sample_id: this.props.selected_row_value.sample_id,
            formula_id: this.props.selected_row_value.formula_id,
            step: 23,
            step_json: JSON.stringify(step_json),
            step_name: 'pill_weight_of_prime_powder',
            step_next: this.state.step_next,
            // performed_by:this.state.performed_by_pin,
            // confirmed_by:this.state.confirmed_by_pin
        }
        axios.post(baseURL + "/mmr-formulations", param, getHeaders())
            .then(res => {
                let result = res.data;
                if (result.success === true) {
                    toast.success("Successfully saved Step");
                    this.props.onNextStep();
                } else {
                    toast.error("Step has not saved");
                }
            })
    }
    render() {
        const { formErrors } = this.state;
        return (
            <>
                <Form onSubmit={this.handleSubmit}>
                    <div className='cs-box'>
                        <div className='cs-body'>
                            <h3>Pill Weight of Prime {this.state.target_pill_weight === "460" ? "Pill" : "Powder"}</h3>
                            <div className='d-flex mt-2 border-b'>
                                <div className='w-60 border-r pe-3'>
                                    <ul className='cs-list'>
                                        <li>Prime Pill Weight:</li>
                                        <li>Place an empty weigh boat on the scale. Then zero the scale with empty weigh boat.</li>
                                        <li>Weigh 10 Prime pills and subtract 1g and divide the difference by 10, Update <a href="https://formulation.flore.com/" target="_blank">www.formulation.flore.com</a> with the calculation.</li>
                                        <li>Input total capsules made and weigh any excess powder remaining, update formulations portal.</li>
                                    </ul>
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <ul className='cs-list'>
                                        <li>Flourish Pill Weight:</li>
                                        <li>Place 4 Flourish pills into QC sachet and weigh</li>
                                        <li>Update <a href="https://formulation.flore.com/" target="_blank">www.formulation.flore.com</a> with filled sachet weight</li>
                                        <li>QC weight will automatically be calculated by the software.</li>
                                        <li>Count number of capsules and weigh any excess powder remaining, update formulations portal.</li>
                                    </ul>
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <ul className='cs-list'>
                                        <li>Place QC sachet into current QC Bin for retaining samples.</li>
                                        <li>Note: Once QC bin is filled label bin clearly with date range and begin new one. QC bin shall be stored in walk in freezer organized by date manufactured.</li>
                                    </ul>
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <ul className='cs-list'>
                                        <li>Maximum of 10 pills shall be added to the Daily Microbial and Chemical Contamination Retention Sample bag.</li>
                                    </ul>
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <ul className='cs-list'>
                                        <li>Click update at bottom of formulation.sungenomics.com operator tab to remove formulation from queue and mark as completed.</li>
                                        <li>Discard all excess powders.</li>
                                    </ul>
                                </div>
                                <div className='w-40 ps-3'>
                                    <div className='mb-2'>
                                        <img className='mxw-100' src={loadImage("step19/19_1.jpg")} alt='img' />
                                        <img className='mxw-100' src={loadImage("step23/1.jpg")} alt='img' />
                                        <img className='mxw-100' src={loadImage("step19/19_2.jpg")} alt='img' />
                                    </div>
                                    <div className='mb-2'>
                                        <img className='mxw-100' src={loadImage("step23/2.jpg")} alt='img' />
                                    </div>
                                    <div className='mb-2'>
                                        <img className='mxw-100' src={loadImage("step23/3.jpg")} alt='img' />
                                    </div>
                                    <div className='mb-2'>
                                        <img className='mxw-100' src={loadImage("step23/4.jpg")} alt='img' />
                                    </div>
                                </div>
                            </div>
                            <div className='mt-5'>
                                {this.props.selected_row_value.flourish_capsule_size === 'powder' ?
                                    <h4>Powdered Weight</h4> :
                                    <h4>Pill Weight</h4>
                                }
                                <div className='custom-table custom-header-table'>
                                    <table className='w-100' cellPadding={0} cellSpacing={0}>
                                        <tbody>
                                            <tr>
                                                <th>{this.state.target_pill_weight === "460" ? "Target Pill Weight(mg)" : "Target Powder Weight(g)"}</th>
                                                <th>Measured QC Weight (g)</th>
                                                {/* <th>Yield(%)</th> */}
                                                <th>Primary Operator</th>
                                                <th>Secondary Signoff</th>
                                            </tr>
                                            <tr>
                                                <td>{this.state.target_pill_weight === "460" ? "380-520" : this.state.target_pill_weight}</td>
                                                <td>
                                                    <Form.Field required fluid="true">
                                                        <Input
                                                            error={formErrors.measured}
                                                            id={`measured`}
                                                            name="measured"
                                                            onChange={this.onInputChange}
                                                            value={this.state.measured ? this.state.measured : ""}
                                                            type="text"
                                                            className="full_opacity form-control w-100 text-center"
                                                        />
                                                    </Form.Field>
                                                </td>
                                                {/* <td className='text-green text-nowrap'>{this.state.yield}</td> */}
                                                <td>
                                                    <Form.Field required fluid="true">
                                                        <Input
                                                            error={formErrors.primary_operator}
                                                            id={`primary_operator`}
                                                            name="primary_operator"
                                                            onChange={this.onInputChange}
                                                            value={this.state.primary_operator ? this.state.primary_operator : ""}
                                                            type="password"
                                                            className="full_opacity form-control w-100 text-center"
                                                        />
                                                    </Form.Field>
                                                    {
                                                        this.state.primary_operator_name && (
                                                            <div className="ui input">{this.state.primary_operator_name}</div>
                                                        )
                                                    }
                                                </td>
                                                <td>
                                                    <Form.Field required fluid="true">
                                                        <Input
                                                            error={formErrors.secondary_signoff}
                                                            id={`secondary_signoff`}
                                                            name="secondary_signoff"
                                                            onChange={this.onInputChange}
                                                            type="password"
                                                            value={this.state.secondary_signoff ? this.state.secondary_signoff : ""}
                                                            className="full_opacity form-control w-100 text-center"
                                                        />
                                                    </Form.Field>
                                                    {
                                                        this.state.secondary_signoff_name && (
                                                            <div className="ui input">{this.state.secondary_signoff_name}</div>
                                                        )
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='mt-5'>
                                <div><h4 className='m-0'>Operator Notes:</h4></div>
                                <div>
                                    <Form.Field required fluid="true">
                                        <TextArea
                                            id="operator_notes"
                                            name="operator_notes"
                                            onChange={this.onInputChange}
                                            value={this.state.operator_notes}
                                            placeholder="Operator Notes"
                                            className="notes white-textarea text-black"
                                        />
                                    </Form.Field>
                                </div>
                            </div>
                            <div className='mt-5'>
                                <div><h4 className='m-0 text-center mb-1'>Statement Of Deviation Requirements:</h4></div>
                                <div><h5 className='m-0 text-center fw-500'>If any process yields fall outside of stated range, conditional step "3C. Process Deviation" is to be conducted.</h5></div>
                            </div>
                            {/* <div className='text-center mt-5'>
                            <div className='d-inline-flex'>
                                <div className='d-flex align-items-center'>
                                    <div><h4 className='m-0 pe-1'>Performed By: </h4></div>
                                    <div className='m-0 pe-1'>
                                        <h4 className='m-0'><u>{this.state.performed_by_name}</u></h4>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <div><h4 className='m-0 pe-1'> PIN</h4></div>
                                    <div className='ui input'>
                                        <Form.Field required fluid="true">                    
                                            <Input
                                            error={formErrors.performed_by_pin}
                                            id="performed_by_pin"
                                            name="performed_by_pin"
                                            onChange={this.onInputChange}
                                            value={this.state.performed_by_pin}
                                            placeholder="Enter Your PIN"
                                            type="password"
                                            className="full_opacity"
                                            autoComplete="new-password"
                                            />
                                        </Form.Field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='text-center mt-2 mb-5'>
                            <div className='d-inline-flex'>
                                <div className='d-flex align-items-center'>
                                    <div><h4 className='m-0 pe-1'>Confirmed By: </h4></div>
                                    <div className='m-0 pe-1'>
                                        <h4 className='m-0'><u>{this.state.confirmed_by_name} </u></h4>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <div><h4 className='m-0 pe-1'> PIN</h4></div>
                                    <div className='ui input'>
                                        <Form.Field required fluid="true">                    
                                            <Input
                                            error={formErrors.confirmed_by_pin}
                                            id="confirmed_by_pin"
                                            name="confirmed_by_pin"
                                            onChange={this.onInputChange}
                                            value={this.state.confirmed_by_pin}
                                            placeholder="Enter Your PIN"
                                            type="password"
                                            className="full_opacity"
                                            autoComplete="new-password"
                                            />
                                        </Form.Field>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        </div>
                        <div className='cs-footer text-center'>
                            <hr />
                            <div className='text-center mt-2'>
                                <button type='button' onClick={this.handleClick} className='gray-btn' >Prev</button>
                                <button type='button' onClick={this.handlePauseClick} className='ui icon secondary labeled button pause-btn ps-1-5em ms-2'>Pause</button>
                                <button type='button' onClick={this.handleQuitClick} className='ui icon secondary labeled button quit-btn ps-1-5em ms-2'>Quit</button>
                                {this.props.step_name === this.state.step_prev && (<button type='submit' className='theme-btn ms-2' >Next</button>)}
                                {this.props.step_name !== this.state.step_prev && (<button type="button" onClick={this.handleNextClick} className="theme-btn ms-2" > Next </button>)}
                            </div>
                        </div>
                    </div>
                </Form>
            </>
        )
    }
}
export default PillWeightOfPrimePowder;