//------------------------------------------------------------------//
//Module: App                                                       //
//Tasks: Redirects to Home Routing page & Display Custom Loader     //
//Author: Yash P Shah                                               //
//------------------------------------------------------------------//


import "./resources/css/App.css";
import Home from "./Home";
import CustomLoader from "./custom_elements/CustomLoader";

import React, { Component } from "react";
import $ from "jquery";
import { ToastContainer} from 'react-toastify';

/*
 * Main entry point to Formula Proforma
 */
class App extends Component {
 
  state = {
    is_page_loading: true
  };

  componentDidMount() {
    // Stop page loading after the page is completely loaded.
    $(window).on("load", () => {
      this.setState({ is_page_loading: false });
    });
  }

  render() {
    return (
      <React.Fragment>
        {this.state.is_page_loading && <CustomLoader />}
        {!this.state.is_page_loading && <Home />}

        <ToastContainer
          position="top-right"
          autoClose={4000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <ToastContainer />
      </React.Fragment>
    );
  }
}

export default App;
