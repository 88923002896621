/* eslint-disable react/no-direct-mutation-state */
import React, { Component } from "react";
import { Form, Input, TextArea } from "semantic-ui-react";
import axios from "axios";
import { baseURL } from "../../../../configuration/constants";
import { getHeaders, isClinical, adult_info, kids_info, baby_info, tots_info, prebiotic_info, loadImage, isProbiotic, clinical_info, clinical_info_prime } from "../../../helper";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { form } from "react-dom-factories";


class Processing extends Component {
    state = { temperature: "", primary_operator_temperature_pin: "", primary_operator_temperature: "", primary_operator_temperature_name: "", secondary_operator_temperature_pin: "", secondary_operator_temperature: "", secondary_operator_temperature_name: "", primary_operator_humidity_pin: "", primary_operator_humidity: "", primary_operator_humidity_name: "", secondary_operator_humidity_pin: "", secondary_operator_humidity: "", secondary_operator_humidity_name: "", performed_by_pin: "", humidity: "", confirmed_by_pin: "", performed_by: "", performed_by_name: "", confirmed_by: "", confirmed_by_name: "", packaging_info: [{}, {}, {}, {}, {}], step_current: {}, is_clinical: false, is_prebiotic: false, is_probiotic: false, formula_type: "", product_type: "", package_material: "PAK_000239", description: "Rigid Pill Box Adult", operator_notes: "", operator_name: "", formErrors: { humidity: false, temperature: false, performed_by_pin: false, confirmed_by_pin: false, primary_operator_temperature_pin: false, secondary_operator_temperature_pin: false, primary_operator_humidity_pin: false, secondary_operator_humidity_pin: false } };
    constructor(props) {
        super(props);
        this.inputRefs = []; // Array to store references to input elements

    }

    componentDidMount() {
        this.getCurrentStep('processing');
        this.state.product_type = this.props.selected_row_value.product_type
        this.state.formula_type = this.props.selected_row_value.flourish_capsule_size;
        this.state.is_clinical = isClinical(this.props.selected_row_value.product_type);

        if (isProbiotic(this.props.selected_row_value.product_type)) {
            let packaging = clinical_info
            if(this.props.selected_row_value.is_prime === 'Yes') {
                packaging = [...packaging, ...clinical_info_prime]
            }
            this.setState({ packaging_info: packaging });
            this.inputRefs = packaging.map(() => React.createRef());
        } else {
            if (this.props.selected_row_value.is_prebiotic === 'Yes') {
                this.state.is_prebiotic = true;
                prebiotic_info[0].quantity = this.props.selected_row_value.number_of_months;
                prebiotic_info[1].quantity = this.props.selected_row_value.number_of_months;
                prebiotic_info[2].quantity = this.props.selected_row_value.number_of_months;
                this.setState({ packaging_info: prebiotic_info });
                this.inputRefs = prebiotic_info.map(() => React.createRef());
            } else {
                this.state.is_probiotic = true;
                switch (this.props.selected_row_value.product_type) {
                    case "monthly_flore":
                        adult_info[1].quantity = adult_info[3].quantity = this.props.selected_row_value.number_of_months;
                        this.setState({ packaging_info: adult_info });
                        this.inputRefs = adult_info.map(() => React.createRef());
                        break;
                    case "monthly_tots":
                        tots_info[1].quantity = tots_info[3].quantity = this.props.selected_row_value.number_of_months;
                        this.setState({ packaging_info: tots_info });
                        this.inputRefs = tots_info.map(() => React.createRef());
                        break;
                    case "monthly_flore_baby":
                        baby_info[1].quantity = baby_info[3].quantity = this.props.selected_row_value.number_of_months;
                        this.setState({ packaging_info: baby_info });
                        this.inputRefs = baby_info.map(() => React.createRef());
                        break;
                    case "monthly_flore_kids":
                        kids_info[1].quantity = kids_info[3].quantity = this.props.selected_row_value.number_of_months;
                        this.setState({ packaging_info: kids_info });
                        this.inputRefs = kids_info.map(() => React.createRef());
                        break;
                    default:
                        if (this.props.selected_row_value.age <= 12) {
                            kids_info[1].quantity = kids_info[3].quantity = this.props.selected_row_value.number_of_months;
                            this.setState({ packaging_info: kids_info });
                            this.inputRefs = kids_info.map(() => React.createRef());
                        } else {
                            adult_info[1].quantity = adult_info[3].quantity = this.props.selected_row_value.number_of_months;
                            this.setState({ packaging_info: adult_info });
                            this.inputRefs = adult_info.map(() => React.createRef());

                        }
                        break;
                }

            }
        }



    }

    getCurrentStep = (step_name) => {
        let req = {
            formula_id: this.props.selected_row_value.formula_id,
            step_name: step_name
        }
        axios.post(baseURL + "/get-current-step", req, getHeaders())
            .then(res => {
                let result = res.data;
                console.log(result);
                if (result.success === true && result.current_step !== null) {
                    let current_step = result.current_step;
                    let step_json = JSON.parse(current_step.step_json);
                    this.setState({ step_current: current_step });
                    // this.onInputChange(0)(null, { name: "performed_by_pin", "value": current_step.performed_by });
                    // this.onInputChange(0)(null, { name: "confirmed_by_pin", "value": current_step.confirmed_by });
                    this.onInputChange(0)(null, { name: "temperature", "value": step_json.temperature });
                    this.onInputChange(0)(null, { name: "primary_operator_temperature_pin", "value": step_json.primary_operator_temperature });
                    this.onInputChange(0)(null, { name: "secondary_operator_temperature_pin", "value": step_json.secondary_operator_temperature });
                    this.onInputChange(0)(null, { name: "humidity", "value": step_json.humidity });
                    this.onInputChange(0)(null, { name: "primary_operator_humidity_pin", "value": step_json.primary_operator_humidity });
                    this.onInputChange(0)(null, { name: "secondary_operator_humidity_pin", "value": step_json.secondary_operator_humidity });
                    this.onInputChange(0)(null, { name: "operator_notes", "value": step_json.operator_notes });

                    this.state.packaging_info = step_json.packaging_info;

                }

            })
    }

    onInputChange = (index) => (e, { name, value }) => {

        switch (name) {
            case 'performed_by_pin':
                if (value.length === 3) {
                    if (this.state.confirmed_by_pin !== value) {
                        this.getPerformUser(value, 'performer');
                    } else {
                        toast.error("Please enter the another pin");
                    }
                } else {
                    this.setState({ performed_by: "", performed_by_name: "" });
                }
                break;
            case 'confirmed_by_pin':
                if (value.length >= 3) {
                    if (this.state.performed_by_pin !== value) {
                        this.getPerformUser(value, 'confirmer');
                    } else {
                        toast.error("Please enter the another pin");
                    }
                } else {
                    this.setState({ confirmed_by: "", confirmed_by_name: "" });
                }
                break;
            case 'barcode':
                let package_material = this.state.packaging_info[index].package_material + "_";
                let length = package_material.length;

                if (value.length > length) {
                    if (!value.includes(package_material)) {
                        toast.error("Please enter vaild barcode");
                        this.state.packaging_info[index].barcode = "";
                        this.state.packaging_info[index].validationErrors = true;
                    } else {
                        this.state.packaging_info[index].barcode = value;
                        this.state.packaging_info[index].validationErrors = false;
                    }
                } else {

                    this.state.packaging_info[index].barcode = value;
                }
                break;

            case 'primary_operator':
                this.state.packaging_info[index].primary_operator = value;
                if (value.length >= 3) {
                    if (this.state.packaging_info[index].secondary_operator !== value) {
                        this.getPerformUser(value, "primary_operator", index);
                    } else {
                        toast.error("Please enter another pin")
                    }
                } else {
                    // eslint-disable-next-line react/no-direct-mutation-state
                    this.state.packaging_info[index].primary_operator_name = "";
                    this.setState({ operator_name: "" });
                }
                break
            case 'secondary_operator':
                this.state.packaging_info[index].secondary_operator = value
                if (value.length >= 3) {
                    if (this.state.packaging_info[index].primary_operator !== value) {
                        this.getPerformUser(value, "secondary_operator", index);
                    } else {
                        toast.error('Please enter another pin');
                    }
                } else {
                    this.state.packaging_info[index].secondary_operator_name = "";
                    this.setState({ operator_name: "" });
                }
                break
            case 'primary_operator_temperature_pin':
                if (value.length === 3) {
                    if (this.state.secondary_operator_temperature_pin !== value) {
                        this.getPerformUser(value, 'primary_operator_temperature_pin');
                    } else {
                        toast.error("Please enter the another pin");
                    }
                } else {
                    this.setState({ primary_operator_temperature: "", primary_operator_temperature_name: "" });
                }
                break;
            case 'secondary_operator_temperature_pin':
                if (value.length >= 3) {
                    if (this.state.primary_operator_temperature_pin !== value) {
                        this.getPerformUser(value, 'secondary_operator_temperature_pin');
                    } else {
                        toast.error("Please enter the another pin");
                    }
                } else {
                    this.setState({ secondary_operator_temperature: "", secondary_operator_temperature_name: "" });
                }
                break;
            case 'primary_operator_humidity_pin':
                if (value.length === 3) {
                    if (this.state.secondary_operator_humidity_pin !== value) {
                        this.getPerformUser(value, 'primary_operator_humidity_pin');
                    } else {
                        toast.error("Please enter the another pin");
                    }
                } else {
                    this.setState({ primary_operator_humidity: "", primary_operator_humidity_name: "" });
                }
                break;
            case 'secondary_operator_humidity_pin':
                if (value.length >= 3) {
                    if (this.state.primary_operator_humidity_pin !== value) {
                        this.getPerformUser(value, 'secondary_operator_humidity_pin');
                    } else {
                        toast.error("Please enter the another pin");
                    }
                } else {
                    this.setState({ secondary_operator_humidity: "", secondary_operator_humidity_name: "" });
                }
                break;
            default:
                break;
        }

        this.setState({ [name]: value });

    };

    getPerformUser = (pin, user_type, index = 0) => {
        if (!pin) {
            toast.error("Please fill pin number.");
            return;
        }
        axios.post(baseURL + "/users/getUserByPin", { pin: pin }, getHeaders())
            .then(res => {
                if (res.data.success === true) {
                    let user = res.data.user;
                    switch (user_type) {
                        case "performer":
                            this.setState(prevState => ({
                                performed_by: user ? user.id : "",
                                performed_by_name: user ? user.full_name : "",
                                formErrors: {
                                    ...prevState.formErrors,
                                    performed_by_pin: user ? false : true
                                }
                            }));
                            break;
                        case "confirmer":
                            this.setState(prevState => ({
                                confirmed_by: user ? user.id : "",
                                confirmed_by_name: user ? user.full_name : "",
                                formErrors: {
                                    ...prevState.formErrors,
                                    confirmed_by_pin: user ? false : true
                                }
                            }));
                            break;
                        case "primary_operator":
                            this.state.packaging_info[index].primary_operator_name = user ? user.full_name : "";
                            this.state.packaging_info[index].formErrors.primary_operator = user ? false : true;
                            this.setState({ packaging_info: this.state.packaging_info });
                            break;
                        case "secondary_operator":
                            this.state.packaging_info[index].secondary_operator_name = user ? user.full_name : "";
                            this.state.packaging_info[index].formErrors.primary_operator = user ? false : true;
                            this.setState({ packaging_info: this.state.packaging_info });
                            break;
                        case "primary_operator_temperature_pin":
                            this.setState(prevState => ({
                                primary_operator_temperature: user ? user.id : "",
                                primary_operator_temperature_name: user ? user.full_name : "",
                                formErrors: {
                                    ...prevState.formErrors,
                                    primary_operator_temperature_pin: user ? false : true
                                }
                            }));
                            break;
                        case "secondary_operator_temperature_pin":
                            this.setState(prevState => ({
                                secondary_operator_temperature: user ? user.id : "",
                                secondary_operator_temperature_name: user ? user.full_name : "",
                                formErrors: {
                                    ...prevState.formErrors,
                                    secondary_operator_temperature_pin: user ? false : true
                                }
                            }));
                            break;
                        case "primary_operator_humidity_pin":
                            this.setState(prevState => ({
                                primary_operator_humidity: user ? user.id : "",
                                primary_operator_humidity_name: user ? user.full_name : "",
                                formErrors: {
                                    ...prevState.formErrors,
                                    primary_operator_humidity_pin: user ? false : true
                                }
                            }));
                            break;
                        case "secondary_operator_humidity_pin":
                            this.setState(prevState => ({
                                secondary_operator_humidity: user ? user.id : "",
                                secondary_operator_humidity_name: user ? user.full_name : "",
                                formErrors: {
                                    ...prevState.formErrors,
                                    secondary_operator_humidity_pin: user ? false : true
                                }
                            }));
                            break;
                        default:
                            break;
                    }
                    //this.setState({user_full_name:user.full_name}); 
                } else {
                    var formErrors = {};
                    if (user_type === 'performer') {
                        formErrors = { performed_by_pin: true };
                    } else if (user_type === 'primary_operator_temperature_pin') {
                        formErrors = { primary_operator_temperature_pin: true };
                    } else if (user_type === 'secondary_operator_temperature_pin') {
                        formErrors = { secondary_operator_temperature_pin: true };
                    } else if (user_type === 'primary_operator_humidity_pin') {
                        formErrors = { primary_operator_humidity_pin: true };
                    } else if (user_type === 'secondary_operator_humidity_pin') {
                        formErrors = { secondary_operator_humidity_pin: true };
                    } else {
                        formErrors = { confirmed_by_pin: true };
                    }

                    this.state({ formErrors });
                }
            })
    }


    handleSubmit = () => {
        let input_field = false;
        let vaild_barcode = false;
        var packaging_info = [];
        this.state.packaging_info.map((value, index) => {
            let barcode = value.barcode
            if (value.quantity == "" || value.primary_operator_name == "" || value.secondary_operator_name == "") {
                input_field = true;
                value.formErrors.quantity = value.quantity ? false : true;
                value.formErrors.primary_operator = value.primary_operator_name ? false : true;
                value.formErrors.secondary_operator = value.secondary_operator_name ? false : true;
            }
            value.formErrors.barcode = value.barcode ? false : true;
            if (!barcode.includes(this.state.packaging_info[index].package_material + "_") || barcode.length === 13) {
                value.formErrors.barcode = true;
                vaild_barcode = true;
            } else {
                value.formErrors.barcode = false;
            }
            packaging_info.push(value);
        });
        this.setState({ packaging_info });
        this.setState((prevState) => ({
            formErrors: {
                ...prevState.formErrors, // spread the existing formErrors
                temperature: this.state.temperature ? (this.state.temperature > 25 || this.state.temperature < 18) ? true : false : true,
                humidity: this.state.humidity ? (this.state.humidity > 50 || this.state.humidity < 35) ? true : false : true,
                // performed_by_pin: !this.state.performed_by_name ? true : false,
                // confirmed_by_pin: !this.state.confirmed_by_name ? true : false,
                primary_operator_temperature_pin: !this.state.primary_operator_temperature_pin ? true : false,
                secondary_operator_temperature_pin: !this.state.secondary_operator_temperature_pin ? true : false,
                primary_operator_humidity_pin: !this.state.primary_operator_humidity_pin ? true : false,
                secondary_operator_humidity_pin: !this.state.secondary_operator_humidity_pin ? true : false,
            }
        }));



        if (!this.state.temperature || input_field) {
            toast.error("Please complete all required field(s)");
            return;
        }
        if (this.state.temperature > 25 || this.state.temperature < 18) {
            toast.error("Please enter temperature within range");
            return;
        }
        if (!this.state.primary_operator_temperature_pin || !this.state.secondary_operator_temperature_pin) {
            toast.error("Operator PIN for temperature is missing");
            return;
        }
        if ((!this.state.primary_operator_temperature_name && this.state.primary_operator_temperature_pin) ||
            (!this.state.secondary_operator_temperature_name && this.state.secondary_operator_temperature_pin)) {
            toast.error("Operator PIN for temperature is incorrect");
            return;
        }
        if (!this.state.humidity && this.state.humidity > 5) {
            toast.error("Please complete all required field(s)");
            return;
        } if (this.state.humidity > 50 || this.state.humidity < 35) {
            toast.error("Please enter humidity within range");
            return;
        }
        if (!this.state.primary_operator_humidity_pin || !this.state.secondary_operator_humidity_pin) {
            toast.error("Operator PIN for humidity is missing");
            return;
        } if ((!this.state.primary_operator_humidity_name && this.state.primary_operator_humidity_pin) ||
            (!this.state.secondary_operator_humidity_name && this.state.secondary_operator_humidity_pin)) {
            toast.error("Operator PIN for humidity is incorrect");
            return;
        }
        if (vaild_barcode) {
            toast.error("Please enter vaild barcode");
            return
        }

        // if (!this.state.confirmed_by_pin || !this.state.performed_by_pin) {
        //     toast.error("PIN is missing");
        //     return;
        // }

        // if ((!this.state.performed_by_name && this.state.performed_by_pin) ||
        //     (!this.state.confirmed_by_name && this.state.confirmed_by_pin)) {
        //     toast.error("PIN is incorrect");
        //     return;
        // }



        let step_json = { temperature: this.state.temperature, humidity: this.state.humidity, operator_notes: this.state.operator_notes, primary_operator_temperature: this.state.primary_operator_temperature_pin, secondary_operator_temperature: this.state.secondary_operator_temperature_pin, primary_operator_humidity: this.state.primary_operator_humidity_pin, secondary_operator_humidity: this.state.secondary_operator_humidity_pin }

        step_json.packaging_info = this.state.packaging_info;

        let param = {
            sample_id: this.props.selected_row_value.sample_id,
            formula_id: this.props.selected_row_value.formula_id,
            step: 7,
            step_json: JSON.stringify(step_json),
            step_name: 'processing',
            step_next: 'gathering_of_flourish_ingredients',
            // performed_by: this.state.performed_by_pin,
            // confirmed_by: this.state.confirmed_by_pin
        }

        axios.post(baseURL + "/mmr-formulations", param, getHeaders())
            .then(res => {
                let result = res.data;
                if (result.success === true) {
                    toast.success("Successfully saved Step");
                    this.props.onNextStep();
                } else {
                    toast.error("Step has not saved");
                }
            })
    }

    handleClick = () => {
        // Call the function passed from the parent
        this.props.onPrevClick('operator_mmr_review');
    };
    handlePauseClick = () => {
        this.props.onNextClick('pause');
    }
    handleQuitClick = () => {
        this.props.onNextClick('quit');
    }

    handleNextClick = () => {
        // Call the function passed from the parent
        this.props.onNextClick('gathering_of_flourish_ingredients')
    };

    focusNextInput = (index) => {
        const nextIndex = index + 1;
        if (nextIndex < this.inputRefs.length) {
            this.inputRefs[nextIndex].current.focus();
        }
    };

    handleKeyPress = (event, index) => {
        if (event.key === 'Enter') {
            this.focusNextInput(index);
            event.preventDefault();
        }

    };

    render() {
        //console.log(this.state);
        const { formErrors } = this.state;

        // this.props.selected_row_value.product_type = "monthly_flore_baby"
        return (
            <>
                <Form onSubmit={this.handleSubmit}>
                    <div className='cs-box'>
                        <div className='cs-body'>
                            <h3>Processing</h3>
                            <div className='white-box'>
                                <div className='cs-common-text border-b pb-2'>
                                    <p>
                                        GMP Room Prep:<br />
                                        Enter GMP room.<br />
                                        Put on gloves.<br />
                                        Confirm air filter, dehumidifier, and AC systems are operational.<br />
                                        Check room conditions in the manufacturing facility using the Elemental Machines Dashboard for
                                        temperature and humidity readings, refer to Section 9.2 for spec limits.<br />
                                        Contact Quality if room is out of spec.
                                    </p>
                                    <p>
                                        Check alcohol spray bottles and fill (if necessary)<br />
                                        Sign into workstation using <em>FF_038 Workstation Log</em></p>
                                </div>
                            </div>
                            {
                                this.state.is_probiotic && (
                                    <div className='mt-2 row'>
                                        <div className='col-md-6 border-r'>
                                            <ul className='cs-list'>
                                                <li>Prepare containers</li>
                                                <li>If 3-month formulation gather:</li>
                                                <li>Record packaging material lot for the following</li>
                                                <li>7"x3.5"x3" plastic bin</li>
                                                <li>Personalized bamboo Prime container (if needed)</li>
                                                <li>3 full strength sachets as denoted by type</li>
                                                <li>1 Prime sachet as denoted by type (if needed)</li>
                                                <li>1 Floré Monthly Box as denoted by type</li>
                                                <li>1 QC sample sachet</li>
                                                <li>2 in process stand up barrier pouches </li>
                                                <li>Desiccants</li>
                                                <li>Instruction card for specific product type</li>
                                                <li>Type information found on specific formulation
                                                    batch record at<br />
                                                    www.formulation.flore.com
                                                </li>
                                                <br />
                                                <li>If 6-month formulation gather:</li>
                                                <li>Record packaging material lot for the folowing:</li>
                                                <li>7"x3.5"x3" plastic bin</li>
                                                <li>Personalized bamboo Prime container (if needed)</li>
                                                <li>6 full strength sachets (Flourish) as denoted by type.</li>
                                                <li>1 Prime sachet as denoted by type (if needed).</li>
                                                <li>1 Floré Monthly Box as denoted by type (Kids, Adults, Tots, Baby).</li>
                                                <li>1 QC sample sachet</li>
                                                <li>2 in process stand up barrier pouches</li>
                                                <li>Desiccants</li>
                                                <li>Instruction card for specific product type</li>
                                                <li>Type information found on specific formulation batch record at <br />
                                                    www.formulation.flore.com
                                                </li>
                                                <br />
                                                <li>Place Dymo labels on:</li>
                                                <li>Floré Box</li>
                                                <li>Pill sachets</li>
                                                <li>In process stand up barrier pouches</li>
                                                <li>QC sachet</li>
                                                <li>Formulation Logbook</li>
                                            </ul>
                                        </div>
                                        {
                                            (this.props.selected_row_value.product_type === "monthly_flore" || (this.props.selected_row_value.age > 12 && this.state.is_clinical)) && (
                                                <div className='col-md-6'>
                                                    <img className='mxw-100' src={loadImage('step7/1.jpg')} alt='label' />
                                                </div>
                                            )

                                        }
                                        {
                                            (this.props.selected_row_value.product_type === "monthly_flore_kids" || (this.props.selected_row_value.age < 12 && this.state.is_clinical)) && (
                                                <div className='col-md-6'>
                                                    <img className='mxw-100' src={loadImage('step7/2.jpg')} alt='label' />
                                                </div>
                                            )
                                        }
                                        {
                                            (this.props.selected_row_value.product_type === "monthly_tots") && (
                                                <div className='col-md-6'>
                                                    <img className='mxw-100' src={loadImage('step7/3.jpg')} alt='label' />
                                                </div>
                                            )


                                        }

                                        {
                                            (this.props.selected_row_value.product_type === "monthly_flore_baby") && (
                                                <div className='col-md-6'>
                                                    <img className='mxw-100' src={loadImage('step7/4.jpg')} alt='label' />
                                                </div>
                                            )
                                        }

                                    </div>
                                )
                            }
                            {
                                !this.state.is_probiotic && (
                                    <div className='mt-2 row'>
                                        <div className='col-md-6 border-r'>
                                            <ul className='cs-list'>
                                                <li>Prepare containers</li>
                                                <li>or every month supply of prebiotic gather:</li>
                                                <li>Record packaging material lot for the following</li>
                                                <li>Tearaway prebiotic pack box</li>
                                                <li>30 prebiotic stick packs</li>
                                                <li>2 in process stand up barrier pouches</li>
                                                <li>Instruction card</li>
                                                <li>Type information found on specific formulation batch record at www.formulation.flore.com</li>
                                                <li>Record lots of pulled packaging material and quantity part number and perform signoff and secondary signoff</li>
                                            </ul>
                                            <ul className='cs-list'>
                                                <li>Place Dymo labels on:</li>
                                                <li>Tearaway box exterior</li>
                                                <li>In process stand up barrier pouches</li>
                                                <li>QC sachet</li>
                                                <li>Formulation Logbook</li>
                                            </ul>
                                            <ul className='cs-list'>
                                                <li>Record environmental conditions for temperature and humidity in popup table</li>
                                            </ul>
                                        </div>
                                        <div className='col-md-6'>
                                            <img className='mxw-100' src={loadImage('step7/5.jpg')} alt='label' />
                                        </div>
                                    </div>
                                )
                            }

                            <div className='mt-5'>
                                <h4>Environmental Conditions</h4>
                                <div className='custom-table custom-header-table'>
                                    <table className='w-100' cellPadding={0} cellSpacing={0}>
                                        <tbody>
                                            <tr>
                                                <th>Measurement</th>
                                                <th>Lower Limit</th>
                                                <th>Upper Limit</th>
                                                <th>Unit</th>
                                                <th className='cs-w-20'>Measured</th>
                                                <th>Primary Operator</th>
                                                <th>Secondary Operator</th>
                                            </tr>
                                            <tr>
                                                <td>Temperature</td>
                                                <td>18</td>
                                                <td>25</td>
                                                <td>C</td>
                                                <td className="cs-w-20">
                                                    <div className='ui input'>
                                                        <Form.Field required fluid="true">
                                                            <Input
                                                                error={formErrors.temperature}
                                                                id="temperature"
                                                                name="temperature"
                                                                onChange={this.onInputChange(0)}
                                                                value={this.state.temperature}
                                                                placeholder="Enter Your Temperature"
                                                                type="text"
                                                                className="full_opacity form-control w-100 text-center"
                                                            />
                                                        </Form.Field>

                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='ui input'>
                                                        <Form.Field required fluid="true">
                                                            <Input
                                                                error={formErrors.primary_operator_temperature_pin}
                                                                id="primary_operator_temperature_pin"
                                                                name="primary_operator_temperature_pin"
                                                                onChange={this.onInputChange(0)}
                                                                value={this.state.primary_operator_temperature_pin}
                                                                placeholder="Primary Operator"
                                                                type="password"
                                                                className="full_opacity form-control w-100 text-center"
                                                            />
                                                        </Form.Field>
                                                    </div>
                                                    {
                                                        this.state.primary_operator_temperature_name && (
                                                            <div className="ui input">{this.state.primary_operator_temperature_name}</div>
                                                        )
                                                    }
                                                </td>
                                                <td>
                                                    <div className='ui input'>
                                                        <Form.Field required fluid="true">
                                                            <Input
                                                                error={formErrors.secondary_operator_temperature_pin}
                                                                id="secondary_operator_temperature_pin"
                                                                name="secondary_operator_temperature_pin"
                                                                onChange={this.onInputChange(0)}
                                                                value={this.state.secondary_operator_temperature_pin}
                                                                placeholder="Secondary Operator"
                                                                type="password"
                                                                className="full_opacity form-control w-100 text-center"
                                                            />
                                                        </Form.Field>
                                                    </div>
                                                    {
                                                        this.state.secondary_operator_temperature_name && (
                                                            <div className="ui input">{this.state.secondary_operator_temperature_name}</div>
                                                        )
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Humidity</td>
                                                <td>35</td>
                                                <td>50</td>
                                                <td>%</td>
                                                <td className="cs-w-20">
                                                    <div className='ui input'>
                                                        <Form.Field required fluid="true">
                                                            <Input
                                                                id="humidity"
                                                                name="humidity"
                                                                onChange={this.onInputChange(0)}
                                                                value={this.state.humidity}
                                                                error={formErrors.humidity}
                                                                placeholder="Enter humidity"
                                                                type="text"
                                                                className="full_opacity form-control w-100 text-center"
                                                            />
                                                        </Form.Field>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='ui input'>
                                                        <Form.Field required fluid="true">
                                                            <Input
                                                                error={formErrors.primary_operator_humidity_pin}
                                                                id="primary_operator_humidity_pin"
                                                                name="primary_operator_humidity_pin"
                                                                onChange={this.onInputChange(0)}
                                                                value={this.state.primary_operator_humidity_pin}
                                                                placeholder="Primary Operator"
                                                                type="password"
                                                                className="full_opacity form-control w-100 text-center"
                                                            />
                                                        </Form.Field>
                                                    </div>
                                                    {
                                                        this.state.primary_operator_humidity_name && (
                                                            <div className="ui input">{this.state.primary_operator_humidity_name}</div>
                                                        )
                                                    }
                                                </td>
                                                <td>
                                                    <div className='ui input'>
                                                        <Form.Field required fluid="true">
                                                            <Input
                                                                error={formErrors.secondary_operator_humidity_pin}
                                                                id="secondary_operator_humidity_pin"
                                                                name="secondary_operator_humidity_pin"
                                                                onChange={this.onInputChange(0)}
                                                                value={this.state.secondary_operator_humidity_pin}
                                                                placeholder="Secondary Operator"
                                                                type="password"
                                                                className="full_opacity form-control w-100 text-center"
                                                            />
                                                        </Form.Field>
                                                    </div>
                                                    {
                                                        this.state.secondary_operator_humidity_name && (
                                                            <div className="ui input">{this.state.secondary_operator_humidity_name}</div>
                                                        )
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='mt-5'>
                                <h4>Packaging Information</h4>
                                <div className='custom-table custom-header-table'>
                                    <table className='w-100' cellPadding={0} cellSpacing={0}>
                                        <tbody>
                                            <tr>
                                                <th>Packaging Material</th>
                                                <th>Description</th>
                                                <th>Quantity</th>
                                                <th>Scan of Barcode</th>
                                                <th>Primary Operator</th>
                                                <th>Secondary Operator</th>
                                            </tr>

                                            {this.state.packaging_info &&
                                                this.state.packaging_info.map((value, index) => {
                                                    return (
                                                        <tr>
                                                            <td>{value.package_material}</td>
                                                            <td className="cs-w-20">
                                                                {value.description}
                                                            </td>
                                                            <td>
                                                                <div className="ui input">
                                                                    <Form.Field required fluid="true">
                                                                        <Input
                                                                            error={value.formErrors ? value.formErrors.quantity : false}
                                                                            id={`quantity` + index}
                                                                            name="quantity"
                                                                            onChange={this.onInputChange(index)}
                                                                            value={value.quantity}
                                                                            placeholder="Quantity"
                                                                            type="text"
                                                                            className="full_opacity form-control w-100 text-center"
                                                                        />
                                                                    </Form.Field>
                                                                </div>
                                                            </td>
                                                            <td className="cs-w-20">
                                                                <div className="ui input">
                                                                    <Form.Field required fluid="true">

                                                                        <Input
                                                                            error={value.validationErrors || value.formErrors ? value.formErrors.barcode : false}
                                                                            id={"barcode" + index}
                                                                            name="barcode"
                                                                            onChange={this.onInputChange(index)}
                                                                            value={value.barcode}
                                                                            placeholder="Scan Of Barcode"
                                                                            type="text"
                                                                            ref={this.inputRefs[index]}
                                                                            onKeyDown={(event) => this.handleKeyPress(event, index)}
                                                                            className="full_opacity form-control w-100 text-center"
                                                                        />
                                                                    </Form.Field>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="ui input">
                                                                    <Form.Field required fluid="true">
                                                                        <Input
                                                                            error={value.formErrors ? value.formErrors.primary_operator : false}
                                                                            id={"primary_operator" + index}
                                                                            name="primary_operator"
                                                                            onChange={this.onInputChange(index)}
                                                                            value={
                                                                                value.primary_operator
                                                                            }
                                                                            placeholder="Primary Operator"
                                                                            type="password"
                                                                            className="full_opacity form-control w-100 text-center"
                                                                        />
                                                                    </Form.Field>
                                                                </div>
                                                                {
                                                                    this.state.packaging_info[index].primary_operator_name && (
                                                                        <div className="ui input">{this.state.packaging_info[index].primary_operator_name}</div>
                                                                    )
                                                                }
                                                            </td>
                                                            <td>
                                                                <div className="ui input">
                                                                    <Form.Field required fluid="true">
                                                                        <Input
                                                                            error={value.formErrors ? value.formErrors.secondary_operator : false}
                                                                            id={"secondary_operator" + index}
                                                                            name="secondary_operator"
                                                                            onChange={this.onInputChange(index)}
                                                                            value={
                                                                                value.secondary_operator
                                                                            }
                                                                            placeholder="Secondary Operator"
                                                                            type="password"
                                                                            className="full_opacity form-control w-100 text-center"
                                                                        />
                                                                    </Form.Field>
                                                                </div>
                                                                {
                                                                    this.state.packaging_info[index].secondary_operator_name && (
                                                                        <div className="ui input">{this.state.packaging_info[index].secondary_operator_name}</div>
                                                                    )
                                                                }
                                                            </td>
                                                        </tr>
                                                    );
                                                })}




                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='mt-5'>
                                <div><h4>Operator Notes:</h4></div>
                                <div>
                                    <Form.Field required fluid="true">
                                        <TextArea
                                            id="operator_notes"
                                            name="operator_notes"
                                            onChange={this.onInputChange(0)}
                                            value={this.state.operator_notes}
                                            placeholder="Operator Notes"
                                            className="form-control w-100 text-left"
                                        />
                                    </Form.Field>

                                </div>
                            </div>
                            {/* <div className='text-center mt-5'>
                                <div className="d-inline-flex">
                                    <div className='d-flex align-items-center'>
                                        <div><h4 className='m-0 pe-1'>Performed By: </h4></div>
                                        <div className='m-0 pe-1'>
                                            <h4 className='m-0'><u>{this.state.performed_by_name}</u></h4>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <div><h4 className='m-0 pe-1'> PIN</h4></div>
                                        <div className='ui input'>
                                            <Form.Field required fluid="true">
                                                <Input
                                                    error={formErrors.performed_by_pin}
                                                    id="performed_by_pin"
                                                    name="performed_by_pin"
                                                    onChange={this.onInputChange(0)}
                                                    value={this.state.performed_by_pin}
                                                    placeholder="Enter Your PIN"
                                                    type="password"
                                                    className="full_opacity form-control w-100 text-center"
                                                    autoComplete="new-password"
                                                />
                                            </Form.Field>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className='text-center mt-2 mb-5'>
                                <div className="d-inline-flex">
                                    <div className='d-flex align-items-center'>
                                        <div><h4 className='m-0 pe-1'>Confirmed By: </h4></div>
                                        <div className='m-0 pe-1'>
                                            <h4 className='m-0'><u>{this.state.confirmed_by_name} </u></h4>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <div><h4 className='m-0 pe-1'> PIN</h4></div>
                                        <div className='ui input'>
                                            <Form.Field required fluid="true">
                                                <Input
                                                    error={formErrors.confirmed_by_pin}
                                                    id="confirmed_by_pin"
                                                    name="confirmed_by_pin"
                                                    onChange={this.onInputChange(0)}
                                                    value={this.state.confirmed_by_pin}
                                                    placeholder="Enter Your PIN"
                                                    type="password"
                                                    className="full_opacity form-control w-100 text-center"
                                                    autoComplete="new-password"
                                                />
                                            </Form.Field>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div className='cs-footer text-center'>
                            <hr />
                            <div className='text-center mt-2'>
                                <button type='button' onClick={this.handleClick} className='gray-btn' >Prev</button>
                                <button type='button' onClick={this.handlePauseClick} className='ui icon secondary labeled button pause-btn ps-1-5em ms-2'>Pause</button>
                                <button type='button' onClick={this.handleQuitClick} className='ui icon secondary labeled button quit-btn ps-1-5em ms-2'>Quit</button>
                                {this.props.step_name === 'operator_mmr_review' && (<button type='submit' className='theme-btn ms-2' >Next</button>)}
                                {this.props.step_name !== "operator_mmr_review" && (<button type="button" onClick={this.handleNextClick} className="theme-btn  ms-2" > Next </button>)}
                            </div>
                        </div>
                    </div>
                </Form>
            </>
        )
    }
}

export default Processing;