import React, { Component, createRef } from "react";
import { Form, Popup, Input, Item, TextArea } from "semantic-ui-react";
import axios from "axios";
import { baseURL } from "../../../../configuration/constants";
import { getHeaders, loadImage, isClinical } from "../../../helper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class EquipmentSanitization extends Component {
  state = {
    performed_by_pin: "",
    performed_by: "",
    performed_by_name: "",
    step_current: {},
    operator_notes: "",
    equipments: [{}, {}],
    step_prev: 'flourish_label_generation',
    formErrors: { performed_by_pin: false }
  };

  constructor(props) {
    super(props);
    this.pinRef = createRef()
  }

  componentDidMount() {
    this.getCurrentStep("equipment_sanitization");
    let is_clinical = isClinical(this.props.selected_row_value.product_type);
    document.addEventListener("keydown", this.handleKeyDown);
    // if (this.props.selected_row_value.is_prime === 'No') {
    //   if (this.props.selected_row_value.flourish_capsule_size !== "powder") {
    //     if (this.props.selected_row_value.product_type === "monthly_flore") {
    //       this.setState({ step_prev: "pill_packaging_flourish" })
    //     } else {
    //       this.setState({ step_prev: "pilling_of_mixed_flourish_ingredient" })
    //     }
    //   } else {
    //     if (this.props.selected_row_value.flourish_capsule_size === "powder") {
    //       this.setState({ step_prev: "pill_packaging_flourish" })
    //     }

    //   }
    // }
  }

  componentWillUnmount() {
    // Clean up the event listener to avoid memory leaks
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  getCurrentStep = (step_name) => {
    let req = {
      formula_id: this.props.selected_row_value.formula_id,
      step_name: step_name,
    };
    axios.post(baseURL + "/get-current-step", req, getHeaders()).then((res) => {
      let result = res.data;
      console.log(result);
      if (result.success === true && result.current_step !== null) {
        let current_step = result.current_step;
        let step_json = JSON.parse(current_step.step_json);
        this.setState({ step_current: current_step });
        this.onInputChange(0)(null, {
          name: "performed_by_pin",
          value: current_step.performed_by,
        });
        this.state.equipments = step_json.equipments;
      }
    });
  };

  onInputChange =
    (i) =>
      (e, { name, value }) => {
        switch (name) {
          case "performed_by_pin":
            if (value.length >= 3) {
              this.getPerformUser(value, "performer");
            } else {
              this.setState({ performed_by: "", performed_by_name: "" });
            }
            break;
          case "confirmed_by_pin":
            if (value.length === 4) {
              this.getPerformUser(value, "confirmer");
            } else {
              this.setState({ confirmed_by: "", confirmed_by_name: "" });
            }
            break;
          case "requiring":
            this.state.equipments[i].requiring = value;
            break;
          case "performed":
            this.state.equipments[i].performed = value;
            break;
          case "log":
            this.state.equipments[i].log = value;
            break;
          default:
            break;
        }

        this.setState({ [name]: value });
      };

  getPerformUser = (pin, user_type) => {
    if (!pin) {
      toast.error("Please fill pin number.");
      return;
    }
    axios
      .post(baseURL + "/users/getUserByPin", { pin: pin }, getHeaders())
      .then((res) => {
        if (res.data.success === true && res.data.user !== null) {
          let user = res.data.user;
          if (user_type === "performer") {
            this.setState(prevState => ({
              performed_by: user ? user.id : "",
              performed_by_name: user ? user.full_name : "",
              formErrors: {
                ...prevState.formErrors,
                performed_by_pin: user ? false : true
              }
            }));
          } else {
            this.setState({
              confirmed_by: user ? user.id : "",
              confirmed_by_name: user ? user.full_name : "",
            });
          }
        }
      });
  };

  handleSubmit = () => {
    /* let input_field = false; 

    this.state.equipments.map((value)=>{
      console.log(Object.keys(value).length);
        if(value.requiring == "" || value.performed == "" || value.log == "" ){
            input_field = true;
        }else if(Object.keys(value).length < 2){
            input_field = true;
        }
    })

    if(input_field){
        toast.error("Please fill all inputs.");
        return;
    } */

    this.setState((prevState) => ({
      formErrors: {
        ...prevState.formErrors, // spread the existing formErrors
        performed_by_pin: !this.state.performed_by_name ? true : false
      }
    }));
    if (!this.state.performed_by_pin) {
      toast.error("PIN is missing");
      return;
    }

    if ((!this.state.performed_by_name && this.state.performed_by_pin)) {
      toast.error("PIN is incorrect");
      return;
    }

    let step_json = {
      equipments: this.state.equipments,
    };

    let param = {
      sample_id: this.props.selected_row_value.sample_id,
      formula_id: this.props.selected_row_value.formula_id,
      step: 25,
      step_json: JSON.stringify(step_json),
      step_name: "equipment_sanitization",
      step_next: "review_finished_product_specifications",
      performed_by: this.state.performed_by_pin,
    };
    axios
      .post(baseURL + "/mmr-formulations", param, getHeaders())
      .then((res) => {
        let result = res.data;
        if (result.success === true) {
          toast.success("Successfully saved Step");
          this.props.onNextStep();
        } else {
          toast.error("Step has not saved");
        }
      });
  };
  handleClick = () => {
    // Call the function passed from the parent
    this.props.onPrevClick(this.state.step_prev);
  };
  handleNextClick = () => {
    // Call the function passed from the parent
    this.props.onNextClick("review_finished_product_specifications");
  };
  handlePauseClick = () => {
    this.props.onNextClick('pause');
  }
  handleQuitClick = () => {
    this.props.onNextClick('quit');
  }

  handleKeyDown = (e) => {
    if (e.key === "Tab") {
      e.preventDefault(); // Prevent default tab behavior
      if (this.pinRef.current) {
        this.pinRef.current.focus(); // Focus the specific input
      }
    }
  };

  render() {
    console.log(this.state);
    return (
      <>
        <Form onSubmit={this.handleSubmit}>
          <div className="cs-box">
            <div className="cs-body">
              <h3>Equipment Sanitization</h3>
              <div className="d-flex mt-2">
                <div className="w-50 border-r pe-3">
                  <ul className="cs-list">
                    <li>
                      Remove all dirty equipment from hood and place in wash
                      bin.
                    </li>
                  </ul>
                </div>
                <div className="w-50 ps-3">
                  <div className="mb-2">
                    <img
                      className="mxw-100"
                      src={loadImage("step25/1.jpg")}
                      alt="img"
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <div className="w-50 border-r pe-3">
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <ul className="cs-list">
                    <li>
                      Gather blue pad and all trash in hood and throw into
                      trash.
                    </li>
                  </ul>
                </div>
                <div className="w-50 ps-3">
                  <div className="mb-2">
                    <img
                      className="mxw-100"
                      src={loadImage("step25/2.jpg")}
                      alt="img"
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex border-b">
                <div className="w-50 border-r pe-3">
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <ul className="cs-list">
                    <li>
                      Follow <em>SOP_017 Standard Sanitization Operating Procedures</em> for hood sanitization instructions.
                    </li>
                  </ul>
                </div>
                <div className="w-50 ps-3">
                  <div className="mb-2">
                    <img
                      className="mxw-100"
                      src={loadImage("step25/3.jpg")}
                      alt="img"
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <div className="w-50 border-r pe-3">
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <ul className="cs-list">
                    <li>Place clean blue pad inside of hood.</li>
                  </ul>
                </div>
                <div className="w-50 ps-3">
                  <div className="mb-2">
                    <img
                      className="mxw-100"
                      src={loadImage("step25/4.jpg")}
                      alt="img"
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <div className="w-50 border-r pe-3">
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <ul className="cs-list">
                    <li>Rinse equipment from wash bin in the GMP sink.</li>
                  </ul>
                </div>
                <div className="w-50 ps-3">
                  <div className="mb-2">
                    <img
                      className="mxw-100"
                      src={loadImage("step25/5.jpg")}
                      alt="img"
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex border-b">
                <div className="w-50 border-r pe-3">
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <ul className="cs-list">
                    <li>
                      Follow <em>SOP_017 Standard Sanitization Operating Procedures</em> for equipment sanitization instructions.
                    </li>
                  </ul>
                </div>
                <div className="w-50 ps-3">
                  <div className="mb-2">
                    <img
                      className="mxw-100"
                      src={loadImage("step25/6.jpg")}
                      alt="img"
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex border-b">
                <div className="w-50 border-r pe-3">
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <ul className="cs-list">
                    <li>
                      Lower hood sash and allow equipment to dry before next
                      use.
                    </li>
                    <li>Confirm sanitization via personal PIN</li>
                  </ul>
                </div>
                <div className="w-50 ps-3">
                  <div className="mb-2">
                    <img
                      className="mxw-100"
                      src={loadImage("step25/7.jpg")}
                      alt="img"
                    />
                    {/* <img
                      className="mxw-100"
                      src={loadImage("step25/7_1.jpg")}
                      alt="img"
                    /> */}
                  </div>
                </div>
              </div>
              <div className="mt-5">
                <h4>Additional Maintenance of equipment:</h4>
                <div className="custom-table custom-header-table">
                  <table className="w-100" cellPadding={0} cellSpacing={0}>
                    <tbody>
                      <tr>
                        <th>Equipment Requiring Maintenance</th>
                        <th>Maintenance Performed</th>
                        <th>
                          Maintenance Recorded in FF_014 Equipment <br />
                          Maintenance Log
                        </th>
                      </tr>
                      {this.state.equipments.map((value, key) => {
                        return (
                          <tr>
                            <td>
                              <Input
                                id={`requiring_` + key}
                                name="requiring"
                                onChange={this.onInputChange(key)}
                                value={value.requiring ? value.requiring : ""}
                                type="text"
                                className="full_opacity form-control w-100 text-center"
                              />
                            </td>
                            <td>
                              <Input
                                id={`performed_` + key}
                                name="performed"
                                onChange={this.onInputChange(key)}
                                value={value.performed ? value.performed : ""}
                                type="text"
                                className="full_opacity form-control w-100 text-center"
                              />
                            </td>
                            <td>
                              <Input
                                id={`log_` + key}
                                name="log"
                                onChange={this.onInputChange(key)}
                                value={value.log ? value.log : ""}
                                type="text"
                                className="full_opacity form-control w-100 text-center"
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="mt-5">
                <div>
                  <h4 className="m-0 text-center mb-1">
                    Full Equipment Mainteance Records can be found in FF_014
                    Equipment Maintenance Logbook by date
                  </h4>
                </div>
              </div>
              <div className="text-center mt-5">
                <div className="d-inline-flex">
                  <div className="d-flex align-items-center">
                    <div>
                      <h4 className="m-0 pe-1">Performed By: </h4>
                    </div>
                    <div className="m-0 pe-1">
                      <h4 className="m-0">
                        <u>{this.state.performed_by_name}</u>
                      </h4>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div>
                      <h4 className="m-0 pe-1"> PIN</h4>
                    </div>
                    <div className="ui input">
                      <Form.Field required fluid="true">
                        <Input
                          error={this.state.formErrors.performed_by_pin}
                          id="performed_by_pin"
                          name="performed_by_pin"
                          onChange={this.onInputChange(0)}
                          value={this.state.performed_by_pin}
                          placeholder="Enter Your PIN"
                          type="password"
                          className="full_opacity"
                          autoComplete="new-password"
                          ref={this.pinRef}
                        />
                      </Form.Field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="cs-footer text-center">
              <hr />
              <div className='text-center mt-2'>
                <button type='button' onClick={this.handleClick} className='gray-btn' >Prev</button>
                <button type='button' onClick={this.handlePauseClick} className='ui icon secondary labeled button pause-btn ps-1-5em ms-2'>Pause</button>
                <button type='button' onClick={this.handleQuitClick} className='ui icon secondary labeled button quit-btn ps-1-5em ms-2'>Quit</button>
                {this.props.step_name === this.state.step_prev && (<button type='submit' className='theme-btn ms-2' >Next</button>)}
                {this.props.step_name !== this.state.step_prev && (<button type="button" onClick={this.handleNextClick} className="theme-btn ms-2" > Next </button>)}
              </div>

            </div>
          </div>
        </Form>
      </>
    );
  }
}
export default EquipmentSanitization;
